import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { useIsMobile } from '@sravni/react-utils';

import type { Application } from '@src/@types/app';
import BottomBannerContainer from '@src/components/BottomBannerContainer';
import { useHeadingTexts } from '@src/components/PageHeader/hooks';
import { PageContext } from '@src/config';
import { getPageListData } from '@src/config/getPageListData';
import type { IPageContextConfig } from '@src/config/PageContext';
import { BottomBannerTypes } from '@src/constants/bottomBannerTypes';
import { MODES } from '@src/constants/filters';
import { ListKeys } from '@src/constants/microcredits';
import ProductList from '@src/containers/list/ProductList';
import { useBestProducts } from '@src/hooks/useBestProducts';
import { useListPage } from '@src/hooks/useListPage';
import { useLoadRestOffers } from '@src/hooks/useLoadRestOffers';
import { getHasUtmLabel, useHasUtmLabel } from '@src/reducers/route';
import { useSelectUser } from '@src/reducers/user';
import { isServer } from '@src/utils/isServer';
import { isMainPage, isNaKartuPage, isZaimyTopPage } from '@src/utils/routing';
import { Layout as AppLayout } from 'app/Layout';
import { useIsPageWithCalculator } from 'features/Calculator';
import { AnchorLinkList } from 'shared/ui';
import { BrandingHeaders } from 'widgets/BrandingHeaders';
import { CalculatorWithHeaders } from 'widgets/CalculatorWithHeaders';
import { SeoComponents } from 'widgets/SeoComponents';

import { getRenderCard, useGetHeaderFilters } from '../lib';

import styles from './ListPage.module.scss';

const pageConfig: IPageContextConfig = {
  listKey: ListKeys.LIST,
  source: 'search',
  sortPanelVisible: true,
  seoLinksVisible: true,
  extraFiltersVisible: true,
  cardTermIsVisible: true,
  withBanners: true,
  withInvitationBanner: true,
  withEmailBurner: true,
  withSelectionBanner: true,
  withCardProductLink: true,
  isMainPage: true,
};

export const ListPage: NextPage = () => {
  const isMobile = useIsMobile();
  const { filter, seo, pathname } = useListPage(ListKeys.LIST);
  const user = useSelectUser();
  const config = useMemo(() => ({ ...pageConfig, withMonetizationCardLogoClick: isMobile }), [isMobile]);
  const { subHeadingText } = useHeadingTexts();
  const hasUtmLabel = useHasUtmLabel();

  const { asPath, query } = useRouter();
  const [pathWithoutQuery] = asPath.split('?');

  const isMain = isMainPage(asPath, query);
  const isNaKartu = isNaKartuPage(asPath);
  const isZaimyTop = isZaimyTopPage(asPath);

  useLoadRestOffers(ListKeys.LIST);
  useBestProducts();

  const isCalculatorVisible = useIsPageWithCalculator();
  const { Filters, isShowFullDealEntrypoint } = useGetHeaderFilters(filter, seo, user);

  return (
    <AppLayout>
      <PageContext.Provider value={config}>
        <BrandingHeaders containerClassName={styles.page_header} afterHeaders={Filters} />

        {isMobile && isZaimyTop && (
          <div className={styles.subheadingContainer}>
            <p className={styles.subheading}>{subHeadingText}</p>
            <AnchorLinkList />
          </div>
        )}

        <ProductList
          className={styles.product_list}
          listClassName={isZaimyTop ? styles.gridLayout : undefined}
          filter={filter}
          pathname={pathname}
          renderCard={(props) => getRenderCard(props, pathWithoutQuery, seo, isMobile, hasUtmLabel)}
        />

        {isCalculatorVisible && !isZaimyTop && <CalculatorWithHeaders filters={filter.filters} />}

        {seo && (
          <SeoComponents
            seo={seo}
            breadcrumbs={seo.breadcrumbs}
            prefooter={seo.prefooter}
            showAnchorLinks={isShowFullDealEntrypoint && !isZaimyTop && !isMain}
            showSubheader={!isNaKartu && !isZaimyTop}
          />
        )}
        <BottomBannerContainer enabledTypes={[BottomBannerTypes.OUT_OF_PAGE, BottomBannerTypes.SIDEBAR]} />
      </PageContext.Provider>
    </AppLayout>
  );
};

ListPage.getInitialProps = (ctx: Application.ReduxNextPageContext) => {
  if (isServer) return {};

  const hasUtmLabel = getHasUtmLabel(ctx.store.getState());

  return getPageListData(ctx, {
    key: ListKeys.LIST,
    defaultFiltersMode: hasUtmLabel ? MODES.AS_UTM : MODES.DEFAULT,
  });
};
